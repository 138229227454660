<template>
    <div>
      <h2>Acerca de</h2>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage' 
  };
  </script>
  
  <style scoped>
  /* Estilos */
  </style>
  